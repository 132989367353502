
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// added to closely approximate the FA colors pending a better import method
$farm-advisor-primary: mat.define-palette(mat.$light-green-palette, 900, 500, 900);
$farm-advisor-accent: mat.define-palette(mat.$grey-palette, 50, 100, 300);
$farm-advisor-warn: mat.define-palette(mat.$deep-orange-palette, 700);

// The warn palette is optional (defaults to red).
// $farm-advisor-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$farm-advisor-theme: mat.define-light-theme((
  color: (
    primary: $farm-advisor-primary,
    accent: $farm-advisor-accent,
    warn: $farm-advisor-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($farm-advisor-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'mapbox-gl/dist/mapbox-gl.css';
@import '~@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~stream-chat-angular/src/assets/styles/v2/css/index.css';

.str-chat {
    flex: 1;
}

.mapboxgl-ctrl-geocoder {
	@apply hidden;
}

// so that the mapbox logo doesn't show super tiny on some browsers
a.mapboxgl-ctrl-logo.mapboxgl-compact {
	width: 88px !important;
}

@font-face {
	font-family: 'Algebra';
	src: url('assets/font/Algebra-RegularNo2.otf') format('opentype');
}

@font-face {
	font-family: "Denim";
	src: url('assets/font/Denim-Regular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Denim";
	src: url('assets/font/Denim-RegularItalic.otf') format('opentype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Denim";
	src: url('assets/font/Denim-SemiBold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Denim";
	src: url('assets/font/Denim-SemiBoldItalic.otf') format('opentype');
	font-weight: 600;
	font-style: italic;
}

@layer base {
	html {
		font-family: "Denim", system-ui, sans-serif;
	}

	p {
		@apply text-base;
	}

	h1, h2, h3, h4 {
		font-family: "Algebra", system-ui, sans-serif;
		@apply text-fa-dark-olive font-medium;
	}

	h1 {
		@apply text-2xl;
	}

	h2 {
		@apply text-xl;
	}

	h3 {
		@apply text-lg;
	}

	h4 {
		@apply text-base;
	}

	.btn {
		@apply py-2
			px-4
			rounded-xl
			border
			cursor-pointer;

		&:disabled {
			@apply cursor-not-allowed
				opacity-50;
		}
	}

	.btn-success {
		@apply bg-fa-dark-olive
			text-white
			hover:bg-green-800
	}

	.btn-danger {
		@apply bg-red-500
			text-white
			hover:bg-red-800
	}

	.text-danger{
		@apply text-red-500
	}
}

@layer components {
	.card {
		@apply bg-white border border-fa-cream rounded-lg w-full;

		.card-header, .card-body {
			@apply w-full px-4 py-3;
		}
		.card-body {
			.card-body-section {
				&:not(:last-child) {
					@apply border-b border-fa-cream;
				}
			}
		}
	}

	.select {
		@apply cursor-pointer
			bg-white
			text-fa-dark-gray
			border
			border-fa-cream
			rounded-xl
			focus:outline-none
			focus:border-fa-dark-olive
			focus:ring-fa-dark-olive;
	}

	.input {
		@apply bg-white
			text-fa-dark-gray
			rounded-xl
			block
			w-full
			border
			border-fa-cream
			focus:border-fa-dark-olive
			focus:ring-fa-dark-olive;
	}

	input:disabled, select:disabled {
		@apply cursor-not-allowed;
	}

	.table {
		@apply bg-white table-fixed w-full text-base;

		thead {
			@apply bg-white border-b border-fa-almost-white;

			th {
				@apply px-8 py-3 whitespace-nowrap text-fa-dark-gray;
			}
		}

		tbody {
			td {
				@apply px-8 py-4 text-fa-dark-olive;
			}
		}

		tfoot {
			@apply border-t border-fa-almost-white;

			th, td {
				@apply px-8 py-3;
			}
		}
	}
}

// material is buggy about hiding this when you tell it to
// so just nuke it
.mat-mdc-button-persistent-ripple {
	display: none !important;
}

/* Allows you to create `a` elements with (click) events without specifying an href or routerLink */
a {
	cursor: pointer;
}

/* match the curve of the rest of the site */
.mapboxgl-popup-content {
	@apply rounded-xl;
}

/* removes weird outline when shift clicking for multi-select */
.mapboxgl-canvas:focus-visible {
	outline: none;
}

/* for the multiselect box */
.map-boxdraw {
	@apply border
		border-fa-safety-orange
		border-dashed
		bg-orange-500
		bg-opacity-25
		absolute
		top-0
		left-0
		w-0
		h-0;
}
html, body { height: 100%; }

/* adds validation coloring for ng-select */
ng-select.ng-invalid.ng-touched .ng-select-container {
	@apply border
		border-fa-safety-orange
}